import { useState } from 'react';

import { ControlledSegmentedControls } from 'lib/common/components/SegmentedControls';

import styles from './playground.module.scss';

export default function Playground() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <div className={styles['playground']}>
      <ControlledSegmentedControls
        options={[
          { label: 'InfoCard' },
          { label: 'Verification' },
          { label: 'VerificationGroup' },
          { label: 'SignIn' }
        ]}
        selectedIndices={[selectedIndex]}
        setSelectedIndices={([index]) => setSelectedIndex(index)}
      />
      testing page
    </div>
  );
}
